/**
 * BlockLiveStreamVideo
 * @param {Object} data, content data
 * @return {Object} normalized data
 */

export const mapperBlockLiveStreamVideo = (data: any) => {
  if (!data) {
    return null
  }
  return {
    id: data.sys?.id || '',
    title: data?.title || '',
    link: data?.link || '', // video external url
    startDate: data?.startDate || null,
    endDate: data?.endDate || null
  }
}
