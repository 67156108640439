import { gql } from 'graphql-tag'
import fragmentFinancialsItemLinks from './fragment-financials-item-links'

export default gql`
  ${fragmentFinancialsItemLinks}
  fragment FinancialsItemFragment on FinancialsItem {
    sys {
      id
    }
    title
    yearsCollection (limit: 10) {
      items {
        ... FinancialsItemLinksFragment
      }
    }
  }
`
