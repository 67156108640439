import {mapperFinancialsItemLinks} from './mapper-financials-item-links'
import type { MapperOptions } from  '../../../core/types'

/**
 * Mapper Financials Item
 */
export const mapperFinancialsItem = (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    return {
        id: data.sys?.id || '',
        contentType: data.contentType || '',
        title: data.title || '',
        years: data.yearsCollection?.items?.map(item => mapperFinancialsItemLinks(item, options))?.filter(Boolean) || []
    }
}
