import {
  format,
  getDate as getDay,
  getYear,
  isAfter,
  isBefore,
  isWithinInterval,
} from "date-fns";

export const useInvestorRelationsUtils = () => {
  /**
   * Get date with Month in english
   */
  const getDate = (date: string) => {
    if (!date) {
      return null;
    }
    const parsedDate = new Date(date);
    const year = getYear(parsedDate);
    const month = format(parsedDate, "MMMM");
    const day = getDay(parsedDate);
    return { year, month, day };
  };

  /**
 * Get the status based on the start and end dates

 */
  const eventTimeState = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
      return null;
    }
    const now = new Date();
    const startDateAsDate = new Date(startDate);
    const endDateAsDate = new Date(endDate);
    const upcoming = isBefore(now, startDateAsDate);
    // Checking that `startDate` is before `endDate` to prevent `isWithinInterval` function throw an "Invalid Interval" error
    const current =
      isBefore(startDateAsDate, endDateAsDate) &&
      isWithinInterval(now, {
        start: startDateAsDate,
        end: endDateAsDate,
      });

    const past = isAfter(now, endDateAsDate);
    return { upcoming, current, past };
  };

  /**
   * Returns the eyebrow string with the city and date
   */
  const titleEyebrow = (city: string, startDate: string) => {
    if (!city || !startDate) {
      return "";
    }
    const date = getDate(startDate);
    return `${city} · ${date.month} ${date.day}, ${date.year}`;
  };

  /**
   * Mapper for the items to be used on DsStackedList
   */
  const mapListItems = (data: any, label: string, getInternalUrl: Function) => {
    if (!data) {
      return []
    }
    return data.map((item) => {
      return {
        title: item.title,
        eyebrow: titleEyebrow(item?.city?.name, item?.startDate),
        year: getDate(item?.startDate)?.year,
        startDate: item?.startDate,
        links: [
          {
            url: getInternalUrl(item?.sys?.id),
            title: label || item.title || "",
            nuxt: true,
            iconRight: { category: "arrows", name: "chevron-right-small" },
          },
        ],
        timeState: eventTimeState(item?.startDate, item?.endDate),
      };
    });
  };

  /**
   * Converts a given number to a million or billion base
   */
  const convertTo = (number: number, to: string = "Billion") => {
    if (!["Million", "Billion"].includes(to)) {
      throw new Error(
        "Invalid conversion parameter: value must equal to 'Million' or 'Billion'"
      );
    }
    if (to === "Million") {
      return number * 1000;
    } else {
      return number * 0.001;
    }
  };
  return { getDate, eventTimeState, mapListItems, titleEyebrow, convertTo };
};
