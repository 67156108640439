import {mapperModuleMedia} from '../../../../core/data-layer/modules/module-media/mapper-module-media'
import type { ModuleMedia } from '../../../../core/types'

export const mapperModuleTradingUpdateItem = (data: any, labels: any) => {
  if (!data) {
    return null
  }
  const asset = mapperModuleMedia(data.asset, null) as ModuleMedia
  const assetLink = asset?.link || {}
  return {
    id: data.sys?.id || '',
    title: data.title || '',
    links: [{
      ...assetLink,
      title: data.linkText || labels.downloadPdf,
      iconRight: { category: 'arrows', name: 'download' }
    }]
  }
}
