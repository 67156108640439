import {
    mapperReportsContentBlock,
    mapperReportsFteGrowthBlock,
    mapperReportsGrowthPillarBlock
} from '../reports/mappers'
import { mapperModuleMedia, mapperModuleUsp } from '../../../core/data-layer/modules/mappers'
import { mapperFinancials } from '../mappers'
import mapperImage from '../../../core/data-layer/utils/mappers/mapper-image'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

const mapperUtil = (data: any, getUrl: Function) => {
    if (!data) {
        return null
    }

    switch (data?.contentType) {
        case 'ReportsFteGrowthBlock':
            return {
                content: {
                    ...mapperReportsFteGrowthBlock(data, { getUrl }),
                    withBackgroundColor: true
                },
                componentName: 'InvestorRelationsShareholderLetterFteBlock'
            }
        case 'ReportsContentBlock':
            return {
                content: mapperReportsContentBlock(data, getUrl),
                componentName: 'InvestorRelationsShareholderLetterContentBlock'
            }
        case 'ReportsHighlightsBlock':
            return {
                content: {
                    items: data.itemsCollection?.items?.map(item => mapperModuleUsp(item)).filter(Boolean) || []
                },
                componentName: 'InvestorRelationsShareholderLetterHighlightsBlock'
            }
        case 'ReportsGrowthPillarBlock':
            return {
                content: mapperReportsGrowthPillarBlock(data, getUrl),
                componentName: 'InvestorRelationsShareholderLetterGrowthPillarBlock'
            }
        default:
            return null
    }
}

const getBackgroundColor = (index, hasTitle, prevItemBackgroundColor, componentName) => {
    if (index === 0) {
        return false
    }
    if (hasTitle) {
        return !prevItemBackgroundColor
    }
    if (componentName === 'InvestorRelationsShareholderLetterFteBlock') {
        return true
    }
    return prevItemBackgroundColor
}

const getLetterItems = items => items?.reduce((acc, current, currentIndex) => {
    const hasBackgroundColor = getBackgroundColor(currentIndex, !!current.content.title, acc[currentIndex - 1]?.content.withBackgroundColor, current.componentName)
    const prevItemBackgroundColor = acc[currentIndex - 1]?.content.withBackgroundColor

    acc.push({
        componentName: current.componentName,
        content: {
            ...current.content,
            withTitleSizeLarge: currentIndex === 0 || !current.content.title?.includes('{'),
            headerMedia: current.content?.headerMedia
                ? mapperImage(current.content?.headerMedia.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
                : null,
            headerMediaBackground: prevItemBackgroundColor ? 'grey-light' : (hasBackgroundColor ? 'light-grey' : ''),
            withBackgroundColor: hasBackgroundColor,
            withoutPaddingTop: !current.content.title || !!current.content?.headerMedia
        }
    })
    return acc
}, [])

const getFinancialItems = (letterItems, items, revenueFlowData) => items?.reduce((acc, current, currentIndex) => {
    const lastLetterItemBackgroundColor = letterItems.length > 0 ? letterItems[letterItems.length - 1].content.withBackgroundColor : null
    const prevItemBackgroundColor = acc[currentIndex - 1]?.content.withBackgroundColor
    acc.push({
        componentName: current.componentName,
        content: {
            ...current.content,
            headerMedia: current.content?.headerMedia
                ? mapperImage(current.content?.headerMedia.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
                : null,
            headerMediaBackground: currentIndex === 0 ? (lastLetterItemBackgroundColor || prevItemBackgroundColor ? 'grey-light' : '') : '',
            withoutPaddingTop: (() => {
                switch (currentIndex) {
                    case 0:
                        return !lastLetterItemBackgroundColor || !!current.content?.headerMedia
                    case 1:
                        return !revenueFlowData
                    default:
                        return true
                }
            })()
        }
    })
    return acc
}, [])

/**
 * Page IR shareholder letter mapper
 * normalizes data to be used in pages

 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    const letterItems = getLetterItems(data.letterItemsCollection?.items?.map(item => mapperUtil(item, options.getUrl)).filter(Boolean) || [])
    const financialItems = getFinancialItems(letterItems, data.discussionOfFinancialResultsItemsCollection?.items?.map(item => mapperUtil(item, options.getUrl)).filter(Boolean) || [], data.revenueFlowData)

    return {
        id: data.sys?.id || '',
        year: data.highlightsYear || 0,
        eyebrow: `${data.highlightsPeriod || ''}${data.highlightsYear ? ` ${data.highlightsYear}` : ''}`,
        image: data?.image
            ? mapperImage((mapperModuleMedia(data.image, options.imageOptions) as ModuleMedia)?.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
            : null,
        highlightsUsps: data.highlightsUspsCollection?.items?.map(item => mapperModuleUsp(item)).filter(Boolean) || [],
        highlightsFootnote: data.highlightsFootnote || '',
        letterItems,
        signatureItems: [
            { name: data.letterSignature1Name || '', role: data.letterSignature1Role || '' },
            { name: data.letterSignature2Name || '', role: data.letterSignature2Role || '' },
            { name: data.letterSignature3Name || '', role: data.letterSignature3Role || '' }
        ].filter(signature => !!signature.name),
        financialItems,
        growthPillarItems: data.growthPillarsItemsCollection?.items?.map(item => mapperReportsGrowthPillarBlock(item, options.getUrl)).filter(Boolean) || [],
        revenueFlowData: data.revenueFlowData || null,
        downloadPdf: (mapperModuleMedia(data.financialStatementsPdf, options.imageOptions) as ModuleMedia)?.link || null,
        financialStatementsTitle: data.financialStatementsTitle || '',
        notice: data.notice || null, // rich text
        pageContentComponentName: 'PageContentShareholderLetter',
        financials: mapperFinancials({ fullYear: data?.financials?.fullYearData, halfYear: data?.financials?.halfYearData, period: data.highlightsPeriod })
    }
}
