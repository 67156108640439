/* eslint-disable import/extensions */
import { format } from 'date-fns/format'
import { getDay } from 'date-fns/getDay'
import { getYear } from 'date-fns/getYear'
import { isBefore } from 'date-fns/isBefore'
import { isWithinInterval } from 'date-fns/isWithinInterval'
import { isAfter } from 'date-fns/isAfter'

/**
 * Get date with Month in english
 * @param {String} date
 * @returns {null|{month: string, year: number, day: number}}
 */
const getDate = (date) => {
  if (!date) { return null }
  const parsedDate = new Date(date)
  const year = getYear(parsedDate)
  const month = format(parsedDate, 'MMMM')
  const day = getDay(parsedDate)
  return { year, month, day }
}

/**
 * Get the status based on the start and end dates
 * @param {String} startDate
 * @param {String} endDate
 * @returns {null|{current: boolean, past: boolean, upcoming: boolean}}
 */
const eventTimeState = (startDate, endDate) => {
  if (!startDate || !endDate) { return null }
  const now = new Date()
  const upcoming = isBefore(now, new Date(startDate))
  const current = isWithinInterval(now, {
    start: new Date(startDate),
    end: new Date(endDate)
  })

  const past = isAfter(now, new Date(endDate))
  return { upcoming, current, past }
}

/**
 * Returns the eyebrow string with the city and date
 * @param {String} city
 * @param {String} startDate
 * @returns {String} City · Month day, year
 */
const titleEyebrow = (city, startDate) => {
  if (!city || !startDate) { return '' }
  const date = getDate(startDate)
  return `${city} · ${date.month} ${date.day}, ${date.year}`
}

const mapListItems = (data: any, label: string, getInternalUrl: Function) => {
  if (!data) {
    return []
  }
  return data.map((item) => {
    const newData = {
      title: item.title,
      eyebrow: titleEyebrow(item?.city?.name, item?.startDate),
      year: getDate(item?.startDate)?.year,
      startDate: item?.startDate,
      links: [
        {
          url: getInternalUrl(item?.sys?.id),
          title: label || item.title || "",
          nuxt: true,
          iconRight: { category: "arrows", name: "chevron-right-small" },
        },
      ],
      timeState: eventTimeState(item?.startDate, item?.endDate),
    };
    return newData
  });
};

/**
 * Converts a given number to a million or billion base
 * @param number - The number to be converted
 * @param {('Million'|'Billion')} to - The base to convert the number to. Can be either million or billion
 * @returns {Number} The converted number
 */
const convertTo = (number, to = 'Billion') => {
  if (!['Million', 'Billion'].includes(to)) {
    throw new Error('Invalid conversion parameter: value must equal to \'Million\' or \'Billion\'')
  }
  if (to === 'Million') {
    return number * 1000
  } else {
    return number * 0.001
  }
}

export { getDate, eventTimeState, mapListItems, titleEyebrow, convertTo }
