import { mapperBlockTabContent } from '../../../core/data-layer/blocks/mappers'
import type { MapperOptions } from '../../../core/types'

/**
 * Page investor relations governance mapper
 * normalizes data to be used in pages

 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    return {
        tabs: data.tabsCollection?.items?.filter(Boolean).map((item: any) => mapperBlockTabContent(item, options)) || []
    }
}
