import { mapperModuleMedia, mapperModuleUsp } from '../../../core/data-layer/modules/mappers'
import { mapperFinancials } from '../mappers'
import mapperImage from '../../../core/data-layer/utils/mappers/mapper-image'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

/**
 * Page IR annual report mapper
 * normalizes data to be used in pages
 *
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    return {
        year: data.year || null,
        eyebrow: data.year.toString(),
        quote: {
            image: data.quoteImage
                ? mapperImage((mapperModuleMedia(data.quoteImage, options.imageOptions) as ModuleMedia)?.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
                : null,
            description: data.quoteText || '',
            authorName: data.quoteAuthorName || '',
            authorRole: data.quoteAuthorRole || ''
        },
        report: {
            title: data.reportTitle || '',
            text: data.reportText || {},
            signature: data.reportSignature || ''
        },
        image: data?.image
            ? mapperImage((mapperModuleMedia(data.image, options.imageOptions) as ModuleMedia)?.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
            : null,
        summary: {
            title: data.summaryTitle || '',
            footnote: data.summaryFootnote || '',
            usps: data.summaryUspsCollection?.items?.map(el => mapperModuleUsp(el)) || []
        },
        downloadPDF: (mapperModuleMedia(data.downloadPdf, {}) as ModuleMedia)?.link,
        pageContentComponentName: 'PageContentAnnualReport',
        financials: mapperFinancials({ fullYear: data?.financials?.fullYearData })
    }
}
