import { format } from 'date-fns'
import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import { type MapperOptions, type ModuleMedia } from '../../../core/types'
const { getI18nFormatterLocale } = useLocales()

export default (data: any, options: MapperOptions, categories = {}) => {
  if (!data?.length) {
    return []
  }
  const dateFnsLocale = getI18nFormatterLocale(options.locale)
  const imageOptions = { resize: true, sizes: { small: { width: 368, height: 207, aspectRatio: '16/9' }, medium: { width: 368, height: 207, aspectRatio: '16/9' }, large: { width: 368, height: 207, aspectRatio: '16/9' } } }
  const getImage = (image: any) =>
    mapperModuleMedia(image, imageOptions)
  return data.map((item: any) => {
    const linkUrl = options.getUrl({ internalLink: item }) || ''
    const image = getImage(item?.image) as ModuleMedia
    return {
      filters: {
        region: item?.city?.country?.region?.sys?.id,
        category: item?.pressCategory,
        year: (new Date(item.date).getFullYear()).toString()
      },
      region: { name: item?.city?.country?.region?.name, id: item?.city?.country?.region?.sys?.id },
      image: image?.medium || { src: '', alt: '' },
      imageWithSizes: image,
      title: item?.title || '',
      category: { name: categories?.[item?.pressCategory] || '', id: item?.pressCategory },
      subtitle: item?.date && item?.city?.name ? `${item.city.name} · ${format(new Date(item.date), 'MMMM dd, yyyy', { locale: dateFnsLocale })}` : '',
      link: {
        title: item?.title,
        nuxt: linkUrl.url.startsWith('/'), // conditionally assign `nuxt` value, when TARGET_SITE=investor-relations then is external link
        openInNewWindow: false,
        url: linkUrl.url,
        lazyUrl: linkUrl.lazyUrlEndpoint
      }
    }
  })
}
