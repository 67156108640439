import { gql } from 'graphql-tag'
import { fragmentModuleMedia } from '../../../core/data-layer/modules/fragments'

export default gql`
  ${fragmentModuleMedia}
  fragment FinancialsItemLinksFragment on FinancialsItemLinks {
    sys {
      id
    }
    contentType: __typename
    year
    h1InternalLink {
      ... on ModuleMedia {
        ...ModuleMediaFragment
      }
      ... on PageInvestorRelationsFinancialsShareholderLetter {
        sys {
          id
        }
      }
      ... on PageInvestorRelationsEventsDetail {
        sys {
          id
        }
      }
      ... on PagePressAndMediaDetail {
        sys {
          id
        }
      }
      contentType: __typename
    }
    h1ExternalLink
    h2InternalLink {
      ... on ModuleMedia {
        ...ModuleMediaFragment
      }
      ... on PageInvestorRelationsFinancialsShareholderLetter {
        sys {
          id
        }
      }
      ... on PageInvestorRelationsEventsDetail {
        sys {
          id
        }
      }
      ... on PagePressAndMediaDetail {
        sys {
          id
        }
      }
      contentType: __typename
    }
    h2ExternalLink
    yearInternalLink {
      ... on ModuleMedia {
        ...ModuleMediaFragment
      }
      ... on PageInvestorRelationsFinancialsAnnualReport {
        sys {
          id
        }
      }
      ... on PageInvestorRelationsEventsDetail {
        sys {
          id
        }
      }
      ... on PagePressAndMediaDetail {
        sys {
          id
        }
      }
      contentType: __typename
    }
    yearExternalLink
    q1InternalLink {
      ... on ModuleMedia {
        ...ModuleMediaFragment
      }
      ... on PageInvestorRelationsEventsDetail {
        sys {
          id
        }
      }
      ... on PageInvestorRelationsShortTradingUpdate {
        sys {
          id
        }
      }
      ... on PagePressAndMediaDetail {
        sys {
          id
        }
      }
      contentType: __typename
    }
    q1ExternalLink
    q3InternalLink {
      ... on ModuleMedia {
        ...ModuleMediaFragment
      }
      ... on PageInvestorRelationsEventsDetail {
        sys {
          id
        }
      }
      ... on PageInvestorRelationsShortTradingUpdate {
        sys {
          id
        }
      }
      ... on PagePressAndMediaDetail {
        sys {
          id
        }
      }
      contentType: __typename
    }
    q3ExternalLink
  }
`
