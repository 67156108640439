export const useInvestorsPageData = () => {
    const dataLayer = useInvestorsDataLayer()
    const { fetchGlobalDataForContentType } = useInvestorsGlobalData()

    const { useAsyncDataWithQuery, useRelatedPagesAsyncDataWithQuery, useStandardAsyncData, onAfterAdyenData, onBeforeAdyenData, pageData, content } = usePageData('investor-relations', {
        layerFragments: dataLayer.fragments,
        layerMappers: dataLayer.mappers,
        fetchGlobalDataFunction: fetchGlobalDataForContentType
    })

    return {
        dataLayer,
        pageData,
        content,
        onBeforeAdyenData,
        onAfterAdyenData,
        useAsyncDataWithQuery,
        useRelatedPagesAsyncDataWithQuery,
        useStandardAsyncData
    }
}
