/**
 * BlockEventForm
 * @param {Object} data, content data
 * @return {Object} normalized data
 */

export const mapperBlockEventForm = (data: any) => {
  if (!data) {
    return null
  }
  return {
    id: data.sys?.id || '',
    formId: data?.formId || '',
    description: data.description || null,
    endDate: data.endDate || null
  }
}
