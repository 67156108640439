import { mapperFinancials, mapperNews } from '../mappers'
import { mapperBlockFaqGroup } from '../../../core/data-layer/blocks/mappers'
import { mapperModuleMedia, mapperModuleNavigationLink, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import mapperImage from '../../../core/data-layer/utils/mappers/mapper-image'
import { useInvestorRelationsUtils } from '../../utils/useInvestorRelationsUtils'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

const mapperUtil = (data: any, labels: any) => {
  if (!data) {
    return null
  }


  const getVideoContent = (data: any) => {
    const videoData = mapperModuleVideo(data, {})
    return {
      ariaLabelPlay: labels.play,
      ariaLabelClose: labels.close,
      thumbnail: videoData?.thumbnail
        ? mapperImage(videoData.thumbnail.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
        : null,
      selfHosted: videoData?.selfHosted,
      src: videoData?.src,
      title: videoData?.title,
      extension: videoData?.extension
    }
  }

  switch (data?.contentType) {
    case 'ModuleMedia':
      // eslint-disable-next-line no-case-declarations
      const media = mapperModuleMedia(data, {}) as ModuleMedia
      return {
        content: data
          ? mapperImage(media?.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
          : null,
        componentName: 'DsImageElement'
      }
    case 'ModuleVideo':
      return {
        content: getVideoContent(data),
        componentName: 'DsVideoPlayer'
      }
    default:
      return null
  }
}

/**
 * Page investor relations mapper
 * normalizes data to be used in pages
 *
 */
export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  const { mapListItems } = useInvestorRelationsUtils()


  const links = [
    (data.headerEventLink && { ...mapperModuleNavigationLink(data.headerEventLink, options.getUrl), iconLeft: { category: 'interface', name: 'play-circle' } }),
    (data.headerResultsLink && { ...mapperModuleNavigationLink(data.headerResultsLink, options.getUrl), iconRight: { category: 'arrows', name: 'chevron-right-small' } })
  ]
  const mappedEvents = data?.events.length ? mapListItems(data.events, options.labels.seeMoreInfo, options.getInternalUrl) : []

  const upcomingEvents = mappedEvents ? mappedEvents.filter(item => item.timeState?.upcoming) : []

  return {
    header: {
      title: data.headerTitle ?? '',
      links: links.filter(Boolean)
    },
    financialsTitle: data.financialsTitle || '',
    financialYear: data?.financials.year || 0,
    financialItems: mapperFinancials({ fullYear: data?.financials.fullYearData, halfYear: data?.financials.halfYearData }),
    seeAllEventsLink: mapperModuleNavigationLink(data.seeAllEventsLink, options.getUrl),
    mediaAsset: mapperUtil(data.mediaAsset, options.labels),
    faq: mapperBlockFaqGroup(data.faq),
    newsTitle: data.labels.news || '',
    news: mapperNews(data?.news, options),
    seeAllNewsLink: mapperModuleNavigationLink(data.labels.seeAllNews, options.getUrl),
    events: {
      label: data.labels.upcomingEvents,
      items: upcomingEvents
    }
  }
}
