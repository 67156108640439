import { gql } from 'graphql-tag'
import fragmentModuleTradingUpdateItem from '../../modules/module-trading-update-item/fragment-module-trading-update-item'

export default gql`
  ${fragmentModuleTradingUpdateItem}
  fragment BlockTradingUpdateFragment on BlockTradingUpdate {
    sys {
      id
    }
    title
    itemsCollection(limit: 4) {
      items {
        sys {
          id
        }
        ...ModuleTradingUpdateItemFragment
      }
    }
  }
`
