import { mapperModuleMediaCard } from '../../../../core/data-layer/modules/mappers'
import { mapperBlockLogoBar } from '../../../../core/data-layer/blocks/mappers'
import type { MapperOptions } from '../../../../core/types'

/**
 * ReportsGrowthPillarBlock
 */

export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    return {
        title: data?.title || '',
        text: data?.text || null, // rich text
        figure: mapperModuleMediaCard(data.image, options),
        logoBar: mapperBlockLogoBar(data.logoBar, options)
    }
}
