export const useNumber = () => {
  /**
   * Converts a given number to a million or billion base
   */
  const convertTo = (number: number, to: string = "Billion") => {
    if (!["Million", "Billion"].includes(to)) {
      throw new Error(
        "Invalid conversion parameter: value must equal to 'Million' or 'Billion'"
      );
    }
    if (to === "Million") {
      return number * 1000
    } else {
      return number * 0.001
    }
  }

  return { convertTo }
}
