import { defu } from 'defu'

// Always add 'min' and 'navigation' categories
const investorsDataCategories = new Map([
    ['PageInvestorRelations', ['investor-relations-labels', 'global-forms', 'form-labels']],
    ['PageInvestorRelationsFinancials', ['investor-relations-labels', 'global-forms', 'form-labels']],
    ['PageInvestorRelationsFinancialsShareholderLetter', ['investor-relations-labels']],
    ['PageInvestorRelationsFinancialsAnnualReport', ['investor-relations-labels']],
    ['PageInvestorRelationsShortTradingUpdate', ['investor-relations-labels']],
    ['PageInvestorRelationsTradingUpdates', ['investor-relations-labels', 'global-forms', 'form-labels']],
    ['PageInvestorRelationsEvents', ['investor-relations-labels']],
    ['PageInvestorRelationsEventsDetail', ['investor-relations-labels', 'global-forms', 'form-labels']],
    ['PageInvestorRelationsGovernance', ['investor-relations-labels']],
    ['PageInvestorRelationsGeneralMeetings', ['investor-relations-labels', 'global-forms', 'form-labels']]

])

export const useInvestorsGlobalData = () => {
    const { labels, globalContentData, localeGlobalContentData, setGlobalContentData, generateDataPromisesForContentType } = useGlobalData()

    async function fetchGlobalDataForContentType (contentType) {
        const promises = generateDataPromisesForContentType(contentType, investorsDataCategories)
        const responses = await Promise.all(promises)

        let data = {}
        responses.forEach((r: any) => {
            data = defu(r, data)
        })

        setGlobalContentData(data)
    }

    return {
        labels,
        globalContentData,
        localeGlobalContentData,
        fetchGlobalDataForContentType
    }
}
