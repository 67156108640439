import { gql } from 'graphql-tag'

export default gql`
    fragment ReportsGrowthPillarBlockFragment on ReportsGrowthPillarBlock {
        title
        text {
            json
        }
        logoBar {
            ...BlockLogoBarFragment
        }
        image {
            ...ModuleMediaCardFragment
        }
    }
`
