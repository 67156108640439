import { gql } from 'graphql-tag'

export default gql`
  fragment ModuleTradingUpdateItemFragment on ModuleTradingUpdateItem {
    sys {
      id
    }
    title
    linkText
    asset {
      src
    }
  }
`
