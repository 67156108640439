/**
 * getAllYearsPerTable
 */
const getAllYearsPerTable = (tableItems: any[]) => {
    const allYears: number[] = tableItems?.flatMap((item) => {
        return item.years.map((yearObj: any) => {
            return yearObj.year
        })
    })
    return ([...new Set(allYears)]).sort((a, b) => b - a)
}

/**
 * getColumnsPerTable
 * @param tableItems {Array} - cms data[tableCollection]?.items
 * @param tableTitle {String} - table title
 * @returns {null|{text: string, key: string}[]} Years mapped to columns
 */
const getColumnsPerTable = (tableItems: any[], tableTitle: string) => {
    const yearsColumns = getAllYearsPerTable(tableItems).map((item, index) => {
        return { key: `col${index + 2}`, text: item || '' }
    })
    if (yearsColumns.length) {
        yearsColumns.unshift({ key: 'col1', text: tableTitle || '' })
        return yearsColumns
    }
    return null
}

/**
 * getRowsPerTable
 * @example col2: [{title: 'H1', url: 'link1', openInNewWindow: false}, {title: 'H2', url: 'link2', openInNewWindow: true}]
 */
const getRowsPerTable = (tableItems: any[], tableTitle: string) => {
    const yearColumnMap = new Map(getColumnsPerTable(tableItems, tableTitle)?.map(item => [item.text, item.key]) || [])

    return tableItems?.map(item =>
        item.years.reduce((acc, yearItem) => {
            const columnKey = yearColumnMap.get(yearItem.year)
            return { ...acc, [columnKey]: yearItem.links }
        }, { col1: item.title || '' })
    )
}

export const mapperFinancialsItemsTable = (title: string, footnote: any, items: any[]) => {
    return {
        title: title || '',
        footnote: footnote || '',
        columns: getColumnsPerTable(items, title) || [],
        rows: getRowsPerTable(items, title) || []
    }
}
