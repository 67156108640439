import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

/**
 * Utility to get corresponding link
 */
const getLink = ({ internal = null, external = null }: any, options: MapperOptions) => {
    if (internal?.contentType === 'ModuleMedia') {
        return (mapperModuleMedia(internal, {}) as ModuleMedia).link
    }
    const url = internal ? options.getInternalUrl(internal?.sys?.id) : external
    const lazyUrlEndpoint = url ? null : '/api/v3/core_routes/entry/' + internal.sys.id + '?locale=' + options.locale
    return {
        url,
        lazyUrlEndpoint,
        nuxt: internal && internal.contentType !== 'PagePlaceholder' && url.startsWith('/')
    }
}

/**
 * Mapper Financials Item Links
 */
export const mapperFinancialsItemLinks = (data: any, options: MapperOptions) => {
    const links = []

    const hasH1andH2 = (data.h1InternalLink || data.h1ExternalLink) && (data.h2InternalLink || data.h2ExternalLink)

    if (data.h1InternalLink || data.h1ExternalLink) {
        // Add H1 available link
        links.push({
            ...getLink({ internal: data.h1InternalLink, external: data.h1ExternalLink }, options),
            title: 'H1'
        })
        // If H1 is available, but not H2, the H2 text should be sent to the table
        if (!hasH1andH2) {
            links.push('H2')
        }
    }

    if (data.h2InternalLink || data.h2ExternalLink) {
        // If H2 is available, but not H1, the H1 text should be sent to the table (before the H2)
        if (!hasH1andH2) {
            links.push('H1')
        }
        // Add H2 available link
        links.push({
            ...getLink({ internal: data.h2InternalLink, external: data.h2ExternalLink }, options),
            title: 'H2'
        })
    }

    const hasQ1andQ3 = (data.q1InternalLink || data.q1ExternalLink) && (data.q3InternalLink || data.q3ExternalLink)

    if (data.q1InternalLink || data.q1ExternalLink) {
        // Add Q1 available link
        links.push({
            ...getLink({ internal: data.q1InternalLink, external: data.q1ExternalLink }, options),
            title: 'Q1'
        })
        // If Q1 is available, but not Q3, the Q3 text should be sent to the table
        if (!hasQ1andQ3) {
            links.push('Q3')
        }
    }

    if (data.q3InternalLink || data.q3ExternalLink) {
        // If Q3 is available, but not Q1, the Q1 text should be sent to the table (before the Q3)
        if (!hasQ1andQ3) {
            links.push('Q1')
        }
        // Add Q3 available link
        links.push({
            ...getLink({ internal: data.q3InternalLink, external: data.q3ExternalLink }, options),
            title: 'Q3'
        })
    }

    if (links.length === 0 && (data.yearInternalLink || data.yearExternalLink)) {
        links.push({
            ...getLink({ internal: data.yearInternalLink, external: data.yearExternalLink }, options),
            title: data.year.toString()
        })
    }

    return {
        id: data.sys?.id || '',
        contentType: data.contentType || '',
        year: data.year,
        links
    }
}
