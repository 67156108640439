import { mapperModuleMedia, mapperModuleNavigationLink, mapperModuleVideo } from '../../../core/data-layer/modules/mappers'
import { mapperBlockEventForm, mapperBlockLiveStreamVideo } from '../../../core/data-layer/blocks/mappers'
import { titleEyebrow } from '../../utils/mapper-investor-relations'
import mapperNews from '../../../core/data-layer/news/mapper-news'
import mapperImage from '../../../core/data-layer/utils/mappers/mapper-image'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

// Util function to determinate if item is of type `ModuleMedia`
const isMedia = item => item?.contentType === 'ModuleMedia'

/**
 * Page investor relations events detail mapper
 * normalizes data to be used in pages
 *

 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }
    const videoData = mapperModuleVideo(data.video, options.imageOptions)

    return {
        id: data.sys?.id || '',
        eyebrow: titleEyebrow(data.city?.name, data.startDate),
        description: data.description || null,
        startDate: data.startDate || null,
        endDate: data.endDate || null,
        form: mapperBlockEventForm(data.form),
        liveStreamVideo: mapperBlockLiveStreamVideo(data.liveStreamVideo),
        relatedDocuments: data.relatedDocumentsCollection?.items?.filter(Boolean).map(
            item => isMedia(item)
                ? (mapperModuleMedia(item, options.imageOptions) as ModuleMedia)?.link
                : mapperModuleNavigationLink(item, options.getUrl)
        ) || [],
        image: data?.bannerImage
            ? mapperImage((mapperModuleMedia(data.bannerImage, options.imageOptions) as ModuleMedia).original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
            : null,
        video: videoData
            ? {
                ariaLabelPlay: options.labels.play,
                ariaLabelClose: options.labels.close,
                thumbnail: videoData?.thumbnail
                    ? mapperImage(videoData.thumbnail.original, { small: { width: 375, height: 184, aspectRatio: '375/184' }, medium: { width: 672, height: 365, aspectRatio: '672/364' }, large: { width: 1200, height: 480, aspectRatio: '5/2' } })
                    : null,
                selfHosted: videoData.selfHosted,
                src: videoData.src,
                title: videoData.title,
                extension: videoData.extension
            }
            : null,
        news: mapperNews(data?.news, options),
        newsTitle: data.labels.news || '',
        seeAllNewsLink: mapperModuleNavigationLink(data.labels.seeAllNews, options.getUrl),
        formSuccessLabels: {
            thankYouMessage: data.labels.thankYouMessage || '',
            weReceivedYourInformationMessage: data.labels.weReceivedYourInformationMessage || ''
        }
    }
}
