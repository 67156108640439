import {mapperModuleTradingUpdateItem} from '../../modules/module-trading-update-item/mapper-module-trading-update-item'
import type { MapperOptions } from '../../../../core/types'

/**
 * Block quote mapper
 */
export const mapperBlockTradingUpdate = (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }

  return {
    id: data.sys?.id || '',
    title: data.title || '',
    items: data.itemsCollection?.items?.filter(Boolean)?.map((item: any) => mapperModuleTradingUpdateItem(item, options.labels)) || []
  }
}
