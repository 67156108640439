import { gql } from 'graphql-tag'

export default gql`
  fragment FinancialsFinancialRecapFragment on FinancialsFinancialRecap {
    sys {
      id
    }
    year
    period
    processedVolumeAmount
    processedVolumeQuantity
    processedVolumeYoy
    processedVolumeMargin
    netRevenueAmount
    netRevenueQuantity
    netRevenueYoy
    netRevenueMargin
    ebitdaAmount
    ebitdaQuantity
    ebitdaYoy
    ebitdaMargin
  }
`
