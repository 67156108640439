import { mapperBlockTradingUpdate } from './blocks/block-trading-update/mapper-block-trading-update'
import { mapperFinancialsItem} from './financials/mapper-financials-item'
import { mapperFinancialsItemLinks} from './financials/mapper-financials-item-links'
import { mapperFinancialsItemsTable} from './financials/mapper-financials-items-table'
import { mapperFinancials, mapperNews } from '../../core/data-layer/mappers'


export { default as PageInvestorRelations } from './page-investor-relations/mapper-page-investor-relations'
export { default as PageInvestorRelationsFinancials } from './page-investor-relations-financials/mapper-page-investor-relations-financials'
export { default as PageInvestorRelationsFinancialsShareholderLetter } from './page-investor-relations-financials-shareholder-letter/mapper-page-investor-relations-financials-shareholder-letter'
export { default as PageInvestorRelationsFinancialsAnnualReport } from './page-investor-relations-financials-annual-report/mapper-page-investor-relations-financials-annual-report'
export { default as PageInvestorRelationsEvents } from './page-investor-relations-events/mapper-page-investor-relations-events'
export { default as PageInvestorRelationsEventsDetail } from './page-investors-relations-events-detail/mapper-page-investor-relations-events-detail'
export { default as PageInvestorRelationsGovernance } from './page-investor-relations-governance/mapper-page-investor-relations-governance'
export { mapperModuleTradingUpdateItem } from './modules/module-trading-update-item/mapper-module-trading-update-item'
import { type MapperOptions } from '../../core/types'


export {
    mapperFinancials,
    mapperNews,
    mapperBlockTradingUpdate,
    mapperFinancialsItem,
    mapperFinancialsItemLinks,
    mapperFinancialsItemsTable
}


export const TypeMapper = {
    BlockTradingUpdate: (data: any, options: MapperOptions) => mapperBlockTradingUpdate(data, options),
    FinancialsItem: (data: any, options: MapperOptions) => mapperFinancialsItem(data, options),
}
