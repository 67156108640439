import { gql } from 'graphql-tag'
import { fragmentModuleMedia, fragmentModuleMediaCard } from '../../../../core/data-layer/modules/fragments'
import { fragmentBlockLogoBar } from '../../../../core/data-layer/blocks/fragments'

export default gql`
    ${fragmentModuleMedia}
    ${fragmentBlockLogoBar}
    ${fragmentModuleMediaCard}
    fragment ReportsContentBlockFragment on ReportsContentBlock {
        title
        subtitle
        text {
            json
        }
        footnote {
            json
        }
        headerMedia {
            ...ModuleMediaFragment
        }
        sideMedia {
            ... on ModuleMedia {
                ...ModuleMediaFragment
            }
            ... on BlockLogoBar {
                ...BlockLogoBarFragment
            }
            ... on ModuleMediaCard {
                ...ModuleMediaCardFragment
            }
            contentType: __typename
        }
        footerMediaCollection (limit: 2) {
            items {
                ... on ModuleMedia {
                    ...ModuleMediaFragment
                }
                ... on ModuleMediaCard {
                    ...ModuleMediaCardFragment
                }
                ... on BlockLogoBar {
                    ...BlockLogoBarFragment
                }
                contentType: __typename
            }
        }
    }
`
