import { mapperModuleMediaCard } from '../../../../core/data-layer/modules/mappers'
import { mapperModuleMedia } from '../../../../core/data-layer/modules/mappers'
import type { MapperOptions } from '../../../../core/types'

/**
 * Reports fte growth block mapper
 */

export default (data: any, options: MapperOptions) => {
  if (!data) {
    return null
  }
  return {
    headerImageGraph: mapperModuleMediaCard(data.headerImageGraph, options),
    imageGraphs: data.imageGraphsCollection?.items?.map(item => mapperModuleMedia(item, options.imageOptions)).filter(Boolean) || []
  }
}
