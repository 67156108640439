import { mapListItems,  } from '../../utils/mapper-investor-relations'
import type { MapperOptions } from '../../../core/types'

/**
 * Orders data based on the start date
 * @param data The events array (pageData.children)
 * @returns {*[]} Sorted events
 */
const sortEvents = (data) => {
    if (data) {
        return data.sort((a, b) => {
            return a?.startDate - b?.startDate ? 1 : -1
        })
    } else {
        return []
    }
}

/**
 * Page investor relations mapper
 * normalizes data to be used in pages
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    const mappedEvents = data?.events ? mapListItems(data.events, options.labels.seeMoreInfo, options.getInternalUrl) : []
    const upcomingEvents = mappedEvents ? mappedEvents.filter(item => item.timeState?.upcoming) : []
    const currentEvents = mappedEvents ? mappedEvents.filter(item => item.timeState?.current) : []
    const pastEvents = mappedEvents ? sortEvents(mappedEvents.filter(item => item.timeState?.past)) : []

    const getYears = (data) => {
        const allYears: number[] = data?.map((item: any) => item.year)
        const uniqueYears = ([...new Set(allYears)]).sort((a, b) => b - a)
        return uniqueYears.map(year => ({ text: year.toString(), value: year }))
    }

    return {
        upcomingEvents: {
            label: data.labels.upcomingEvents || '',
            items: upcomingEvents
        },
        currentEvents: {
            label: data.labels.currentEvents || '',
            items: currentEvents
        },
        pastEvents: {
            label: data.labels.pastEvents || '',
            items: pastEvents,
            filterOptions: pastEvents.length ? getYears(pastEvents) : []
        }
    }
}
