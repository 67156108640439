import { mapperModuleMedia } from '../../../core/data-layer/modules/mappers'
import { mapperFinancialsItem } from '../financials/mapper-financials-item'
import { mapperFinancialsItemsTable } from '../financials/mapper-financials-items-table'
import type { MapperOptions, ModuleMedia } from '../../../core/types'

/**
 * Page content financials overview
 * normalizes data to be used in the page
 *
 */
export default (data: any, options: MapperOptions) => {
    if (!data) {
        return null
    }

    // Map financials items
    const financialResultsItems = data.itemsFinancialsCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []
    const annualReportItems = data.itemsReportCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []
    const capitalMarketsDayItems = data.itemsCapitalMarketsDayCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []
    const agmItems = data.itemsAgmCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []
    const egmItems = data.itemsEgmCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []
    const businessUpdatesItems = data.itemsBusinessUpdatesCollection?.items?.map(item => mapperFinancialsItem(item, options)).filter(Boolean) || []

    return {
        items: [
            {
                title: data.sectionTitlePublications || '',
                items: [
                    // Financial results
                    mapperFinancialsItemsTable(data.titleFinancials, data.noteFinancials, financialResultsItems),
                    // Annual report
                    mapperFinancialsItemsTable(data.titleReport, data.noteReport, annualReportItems)
                ]
            },
            {
                title: data.sectionTitleBusinessUpdates || '',
                items: [
                    // Business Updates
                    mapperFinancialsItemsTable(data.titleBusinessUpdates, data.notesBusinessUpdates, businessUpdatesItems)
                ]
            },
            {
                title: data.sectionTitleDocuments || '',
                items: [
                    // Capital Markets and Investor Days
                    mapperFinancialsItemsTable(data.titleCapitalMarketsDay, data.noteCapitalMarketsDay, capitalMarketsDayItems),
                    // AGM
                    mapperFinancialsItemsTable(data.titleAgm, data.noteAgm, agmItems),
                    // EGM
                    mapperFinancialsItemsTable(data.titleEgm, data.noteEgm, egmItems)
                ]
            }
        ],
        otherItems: {
            title: data.titleOther || '',
            items: data.itemsOtherCollection?.items?.map(item => (mapperModuleMedia(item, {}) as ModuleMedia)?.link) || []
        }
    }
}
